import { Outlet, useNavigate } from "react-router-dom";
import { Sidebar } from "../../Shared/layout/Sildebar/Sidebar";
import { Suspense, useEffect, useState } from "react";
import RootVankSkeleton from "./RootVankSkeleton";
import SendModal from "./SendModal/SendModal";
import ReceiveModal from "./ReceiveModal/ReceiveModal";
import SidebarNotifications from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarNotifications/SidebarNotifications";
import "./RootVank.css";
import { useTranslation } from "react-i18next";
import FullScreenLoader from "@/apps/Shared/FullScreenLoader/FullScreenLoader";
import webSocketManager from "@/apps/contextSocket/SocketManager";
import { SocketProvider } from "@/apps/contextSocket/WebSocketContext";
import SidebarProfile from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarProfile/SidebarProfile";
import { userInfoQuery } from "@/apps/Shared/hooks/queries/userInfoQuery";
import { useCacheQuery } from "@/apps/Shared/hooks/queries/useCacheQuery";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { messageModalState } from "@/apps/Shared/state/atoms";
import { useSetRecoilState } from "recoil";
import ServerError from "@/apps/Shared/ServerError/ServerError";

export const RootVank = ({
  setProfileModalOpen,
}: {
  setProfileModalOpen?: any;
}) => {
  const [isOpenSend, setIsOpenSend] = useState(false);
  const [isOpenReload, setIsOpenReload] = useState(false);
  const [t] = useTranslation("global");
  const setMessageModal = useSetRecoilState(messageModalState);
  const [statusMessage, setStatusMessage] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const { status: cacheDataStatus } = useCacheQuery();

  const {
    status: userInfoStatus,
    error: userInfoError,
    isError,
    failureCount,
  } = userInfoQuery();

  useEffect(() => {
    if (isError) {
      const error = userInfoError as any;
      if (error?.data?.statusCode === 500) {
        setError(true);
        return;
      }
      if (error?.message === "session-expired") {
        handleSessionExpired(1);
        return;
      }
      if (error?.message === "not-token") {
        handleSessionExpired(2);
        return;
      }
      setError(true);
    }
  }, [userInfoError, isError]);

  const handleSessionExpired = async (type: number) => {
    await StorageService.clear();
    navigate("/auth/SingIn");
    if (type === 1) {
      setMessageModal({
        title: t("Share.tokenExpired.Title"),
        message: t("Share.tokenExpired.message"),
        show: true,
        type: "session-expired",
        url: "", // Agregar este campo para evitar el error
      });
    }
  };

  useEffect(() => {
    document.addEventListener("SendModal", () => setIsOpenSend(true));
    document.addEventListener("ReceiveModal", () => setIsOpenReload(true));
    // Limpiar las conexiones al desmontar
    return () => {
      // Cerrar todas las conexiones WebSocket cuando el componente se desmonte
      webSocketManager.closeAllWebSockets();
    };
  }, []);

  const loading = userInfoStatus === "pending" || cacheDataStatus === "pending";

  useEffect(() => {
    if (failureCount >= 3) {
      setError(true);
    } else if (failureCount >= 2) {
      setStatusMessage(t("Share.error.retrying"));
    }
  }, [failureCount]);

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      {loading ? (
        <FullScreenLoader text={statusMessage} />
      ) : (
        <Suspense fallback={<RootVankSkeleton />}>
          <SocketProvider auth={userInfoStatus === "success" || loading}>
            <div className="relative w-full bg-[--color-lighter-white] dark:bg-[var(--background-layaout-dark)] justify-start items-center inline-flex overflow-hidden">
              <Sidebar
                setIsOpenSend={setIsOpenSend}
                setIsOpenReload={setIsOpenReload}
              />
              <div className="relative w-full h-auto flex justify-center items-center 2xl:overflow-auto xl:overflow-auto lg:overflow-auto tablet:overflow-auto mobile:overflow-hidden">
                <main className="relative w-full md:max-w-[1308px] mobile:w-full 2xl:h-screen xl:h-screen lg:h-screen tablet:h-screen mobile:h-auto 2xl:overflow-auto xl:overflow-auto lg:overflow-auto tablet:overflow-auto mobile:overflow-hidden">
                  <Outlet />
                </main>
              </div>

              <SidebarNotifications />
              <SidebarProfile setProfileModalOpen={setProfileModalOpen} />

              <SendModal
                isOpenSend={isOpenSend}
                setIsOpenSend={setIsOpenSend}
              />
              <ReceiveModal
                isOpenReload={isOpenReload}
                setIsOpenReload={setIsOpenReload}
              />
            </div>
          </SocketProvider>
        </Suspense>
      )}
    </>
  );
};
