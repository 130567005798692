import { SocketConfig } from "./types";
import { environment } from "@/environments/environment.dev";

export const socketConfigs = [
  {
    id: "operations",
    url:`${environment.url_socket.url_operations}/RequestOperationsServices`,
    visible: true,
  },
  {
    id: "orchestrator",
    url:`${environment.url_socket.url_vank_orchestractor}/RequestVankOrchestratorServices`,
    ecs: environment.headers.Vankorchestrator,
    visible: true,
  },
] as SocketConfig[];
