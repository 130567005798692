import React, { createContext, useContext, useEffect, useState } from "react";
import { ServiceType, SocketContextType, SocketProviderProps } from "./types";
import webSocketManager from "./SocketManager";
import { socketConfigs } from "./environment.socket";
import FullScreenLoader from "../Shared/FullScreenLoader/FullScreenLoader";
import { useTranslation } from "react-i18next";
import ServerError from "../Shared/ServerError/ServerError";
// import Reconnecting from "../Shared/Reconnecting/Reconnecting";

// Inicializa el contexto con un valor por defecto
const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const SocketProvider: React.FC<SocketProviderProps> = ({
  children,
  auth,
}) => {
  const [t] = useTranslation("global");

  const [connectionDetails, setConnectionDetails] = useState<{
    [key: string]: { connected: boolean; error?: string };
  }>({});

  const [statusMessage, setStatusMessage] = useState("");
  const [failed, setFailed] = useState(false);

  const handleWebSocketMessage = (id: ServiceType, event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      const { keyPublic, clientId } = data.body;
      if (
        ["connectionsStatus", "connectionsStatusVankOrchestrator"].includes(
          data?.event
        )
      ) {
        webSocketManager.saveConnectionData(id, { keyPublic, clientId });
        setConnectionDetails((prev) => ({
          ...prev,
          [id]: { connected: true },
        }));
      }
    } catch (error) {
      console.error(`[${id}] Error processing WebSocket message:`, error);
    }
  };

  useEffect(() => {
    // Crear las conexiones

    if (auth) {
      socketConfigs
        .filter((item) => item.visible === true)
        .forEach(({ id, url }) => {
          if (!connectionDetails[id]?.connected) {
            webSocketManager
              .createWebSocket(id, url)
              .then(() => {
                const connection = webSocketManager.connections[id];
                connection.onmessage = (event) =>
                  handleWebSocketMessage(id, event);
              })
              .catch((error) =>
                setConnectionDetails((prev) => ({
                  ...prev,
                  [id]: { connected: false, error: error?.message },
                }))
              );
          }
        });
    }
  }, [socketConfigs]);

  useEffect(() => {
    const handleReconnecting = (event) => {
      // setReconnectCount(event.detail);
      // console.log(`🔄 Intentando reconectar... Intento: ${event.detail}`);
      if (event.detail <= 5){
        setStatusMessage(t("Share.error.reconnect.attempt"));
        return;
      }
      if (event.detail <= 15){
        setStatusMessage(t("Share.error.reconnect.issues"));
        return;
      }
      if (event.detail <= 20){
        setStatusMessage(t("Share.error.reconnect.warning"));
        return;
      }
    };

    const handleFailed = () => {
      setFailed(true);
      console.log("❌ No se pudo reconectar después de varios intentos.");
    };

    window.addEventListener("websocket-reconnecting", handleReconnecting);
    window.addEventListener("websocket-failed", handleFailed);

    return () => {
      window.removeEventListener("websocket-reconnecting", handleReconnecting);
      window.removeEventListener("websocket-failed", handleFailed);
    };
  }, []);

  if (failed) {
    return <ServerError />;
  }

  // Verificar si todos los servicios visibles están conectados
  const allServicesConnected = socketConfigs
    .filter((item) => item.visible === true)
    .every(({ id }) => connectionDetails[id]?.connected === true);

  if (!allServicesConnected) {
    return <FullScreenLoader text={statusMessage} />;
  }

  return (
    <SocketContext.Provider value={{ webSocketManager, connectionDetails }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook para consumir el contexto
export const useSocket = (serviceName: string) => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  const { webSocketManager, connectionDetails } = context;
  const service = connectionDetails[serviceName];

  // Verifica si el servicio existe en el estado de las conexiones
  if (!service) return;

  return {
    socket: webSocketManager.connections[serviceName] || null, // Devuelve el socket correspondiente
    method: webSocketManager, // Devuelve el manager completo
    connected: connectionDetails[serviceName],
  };
};
