import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { ArrowHide } from "@/assets/Icon/ArrowHide";
import {
  IconClose,
  IconCrypto,
  IconDolar,
  IconVVankPay,
} from "@/assets/IconV2/IconsSvg";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SendModal = ({
  isOpenSend,
  setIsOpenSend,
}: {
  isOpenSend: any;
  setIsOpenSend: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverPay, setIsHoverPay] = useState(false);
  const [isHoverCrypto, setIsHoverCrypto] = useState(false);
  const [isHoverDolar, setIsHoverDolar] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  useEffect(() => {
    // Función para verificar si el dispositivo es móvil
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    // Verifica el tamaño de la ventana al cargar el componente
    checkMobile();

    // Añade un listener para manejar el cambio de tamaño de la ventana
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile); // Limpia el listener
    };
  }, []);

  const handleMouseHoverClose = (hovered: boolean) => {
    setIsHovered(hovered);
  };

  const handleMouseHoverPay = (hovered: boolean) => {
    setIsHoverPay(hovered);
  };

  const handleMouseHoverCrypto = (hovered: boolean) => {
    setIsHoverCrypto(hovered);
  };

  const handleMouseHoverDolar = (hovered: boolean) => {
    setIsHoverDolar(hovered);
  };

  const handleNavigate = (path: string) => {
    setIsOpenSend(false);
    setIsHovered(false);
    setIsHoverPay(false);
    setIsHoverCrypto(false);
    setIsHoverDolar(false);
    setTimeout(() => {
      navigate(path);
    }, 70);
  };

  return (
    <CustomModal isOpen={isOpenSend}>
      <motion.div
        className="lg:w-[550px] md:w-[550px] sm:w-[550px] 2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute mobile:bottom-0 mobile:w-full px-4 py-6 bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] xl:rounded-2xl 2xl:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl flex-col  gap-6 inline-flex"
        initial={isMobile ? { y: "100%" } : { y: 0 }} // Solo en móvil comienza fuera de la pantalla
        animate={{ y: 0 }} // Siempre termina en su posición original
        exit={isMobile ? { y: "100%" } : { y: 0 }} // Sale hacia abajo solo en móvil
        transition={{ duration: 0.3, ease: "easeInOut" }} // Duración y suavizado de la transición
      >
        <div className="2xl:w-[518px]  xl:w-[518px] lg:w-[518px] md:w-[518px] sm:w-[518px] mobile:w-full  flex-col justify-start items-start inline-flex">
        
          <div className="w-full h-7 justify-between items-center inline-flex ">
            <span className="w-full text-[var(--content-main-black-light)] dark:text-[#FFFFFF] 2xl:text-[22px] xl:text-[22px] lg:text-[22px] md:text-[22px] sm:text-[22px] mobile-375:text-[18px] mobile:text-[17px] font-semibold">
              {t("Vank.Share.Sidebar.SendModal.title")}
            </span>
            <CustomButton
              className={`w-7 h-7 rounded-[500px] 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#F1F1F1] justify-center items-center flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#212222] ${theme === "dark" ? "dark-theme-back" : "light-theme-back"
                } transition-all duration-300`}
              onclick={() => {
                setIsOpenSend(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                className="w-[18px] h-[18px] icon-svg"
              >
                <path
                  d="M5 5.5L13 13.5"
                  // stroke="var(--content-main-gray-light)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 5.5L5 13.5"
                  // stroke="var(--content-main-gray-light)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </CustomButton>
          </div>
        </div>

        <div className="2xl:w-auto xl:w-auto lg:w-auto tablet:w-full mobile:w-full  gap-4 flex flex-col">

          {/*VankPay*/}
          <div
            className="hover:bg-[var(--background-disabled-input)] border border-[#EAEAEA] dark:border-[var(--strokes-pills-default-light)]  2xl:w-full xl:w-full lg:w-full tablet:w-full mobile:w-full  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[var(--background-hover-dark)] text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)] rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
            onMouseEnter={() => handleMouseHoverPay(true)}
            onMouseLeave={() => handleMouseHoverPay(false)}
            onClick={() => handleNavigate("/send/vankpay")}
          >
            <div className="flex gap-3 group-hover:gap-2">
              <div className=" group-hover:h-[69px] group-hover:scale-[0.95]  flex justify-center items-center   transition-all ease-in-out duration-300">

                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="44" height="44" rx="22" fill="#2D2E2F" />
                  <path d="M25.019 30.5C25.0172 30.3723 25.0114 30.2453 25.0114 30.1168C25.0114 26.9935 25.5327 23.9351 26.5749 20.9434C27.6175 17.9517 29.0094 15.471 30.75 13.5H26.5343C24.8848 15.2304 23.5561 17.6096 22.5479 20.6372C21.5401 23.6647 21.036 26.8251 21.036 30.1168C21.036 30.2453 21.0418 30.3719 21.0433 30.5H25.019ZM17.2267 30.5H21.3177L17.091 14.1027H13L17.2267 30.5Z" fill="#FFF133" />
                </svg>

              </div>

              <div className="flex flex-col items-start">
                <div className="text-center text-base font-semibold ">
                  {t("Vank.Share.Sidebar.SendModal.Actions.VankPay.title")}
                </div>
                <div className="group-hover:block text-left 2xl:text-sm xl:text-sm lg:text-sm tablet:text-sm mobile:text-xs text-[#818282] dark:text-[var(--content-main-gray-dark)]">
                  {t("Vank.Share.Sidebar.SendModal.text1")}
                </div>
              </div>

            </div>

            <div>
              <ArrowHide className="-rotate-90 w-full h-full " />
            </div>

          </div>

          {/*Cripto  */}
          <div className="hover:bg-[var(--background-disabled-input)]  border border-[#EAEAEA] dark:border-[var(--strokes-pills-default-light)]  2xl:w-full xl:w-full lg:w-full tablet:w-full mobile:w-full  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[var(--background-hover-dark)] text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)] rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
            onMouseEnter={() => handleMouseHoverCrypto(true)}
            onMouseLeave={() => handleMouseHoverCrypto(false)}
            onClick={() => handleNavigate('/send/crypto')}
          >

            <div className="flex gap-3 group-hover:gap-2 f">

              <div className=" group-hover:h-[69px] group-hover:scale-[0.95] flex justify-center items-center   transition-all ease-in-out duration-300">

                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_2388_63043)">
                    <rect width="44" height="44" rx="22" fill="#FFD595" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.1643 18.0278C19.8894 18.0278 18.0453 19.8622 18.0453 22.125C18.0453 24.3878 19.8894 26.2222 22.1643 26.2222C23.8703 26.2222 25.3377 25.1903 25.963 23.7132L29 24.9852C27.8784 27.6348 25.2423 29.5 22.1643 29.5C18.0695 29.5 14.75 26.1981 14.75 22.125C14.75 18.0519 18.0695 14.75 22.1643 14.75C25.2423 14.75 27.8784 16.6152 29 19.2648L25.963 20.5368C25.3377 19.0597 23.8703 18.0278 22.1643 18.0278Z" fill="#2D2E2F" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.5 12.25V17H19.5V12.25H21.5ZM21.5 27.5V31.75H19.5V27.5H21.5Z" fill="#2D2E2F" />
                    <path d="M24.5 12.25V17H22.5V12.25H24.5Z" fill="#2D2E2F" />
                    <path d="M24.5 27.5V31.75H22.5V27.5H24.5Z" fill="#2D2E2F" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2388_63043">
                      <rect width="44" height="44" rx="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className="flex flex-col items-start">
                <div className="text-center text-base font-semibold ">
                  {t("Vank.Share.Sidebar.SendModal.Actions.Crypto.title")}
                </div>
                <div className="group-hover:block 2xl:text-sm xl:text-sm lg:text-sm tablet:text-sm mobile:text-xs text-left text-[#818282] dark:text-[var(--content-main-gray-dark)]">
                  {t("Vank.Share.Sidebar.SendModal.text2")}
                </div>
              </div>

            </div>

            <div>
              <ArrowHide className="-rotate-90 w-full h-full " />
            </div>

          </div>

          {/* Dinero */}
          <div
            className="hover:bg-[var(--background-disabled-input)] border border-[#EAEAEA] dark:border-[var(--strokes-pills-default-light)] 2xl:w-full xl:w-full lg:w-full tablet:w-full mobile:w-full  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[var(--background-hover-dark)] text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)] rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
            onMouseEnter={() => handleMouseHoverDolar(true)}
            onMouseLeave={() => handleMouseHoverDolar(false)}
            onClick={() => handleNavigate("/send/fiat")}
          >

            <div className="flex gap-3 group-hover:gap-2 f">
              <div className=" group-hover:h-[69px] group-hover:scale-[0.95] flex justify-center items-center  transition-all ease-in-out duration-300">

                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_2388_63054)">
                    <rect width="44" height="44" rx="22" fill="#FFF133" />
                    <g filter="url(#filter0_d_2388_63054)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M16.8855 18.9479C16.8855 16.2173 19.4024 14.3809 22.0034 14.3809C23.8751 14.3809 25.6108 15.2878 26.5103 16.7746L27.2498 17.9969L24.8052 19.4758L24.0657 18.2535C23.7418 17.7181 22.9926 17.238 22.0034 17.238C20.5295 17.238 19.7427 18.2113 19.7427 18.9479C19.7427 19.2691 19.8534 19.5111 20.2368 19.7954C20.6863 20.1286 21.4016 20.4232 22.4016 20.7115C23.466 21.0184 24.5961 21.4259 25.4761 22.0796C26.422 22.7823 27.1236 23.8113 27.1236 25.2226C27.1236 27.9538 24.6061 29.7897 22.0034 29.7897C20.1325 29.7897 18.4 28.8816 17.5012 27.396L16.7617 26.1737L19.2063 24.6948L19.9458 25.9171C20.2704 26.4536 21.018 26.9325 22.0034 26.9325C23.4803 26.9325 24.2665 25.9588 24.2665 25.2226C24.2665 24.9003 24.1553 24.6576 23.7724 24.3732C23.3237 24.0399 22.6091 23.7449 21.61 23.4568C20.5463 23.1501 19.4158 22.7434 18.5353 22.0906C17.5888 21.389 16.8855 20.3604 16.8855 18.9479Z" fill="#2D2E2F" />
                      <path d="M20.8093 12.7142H22.7141V15.5714H20.8093V12.7142Z" fill="#2D2E2F" />
                      <path d="M20.8093 28.4285H22.7141V31.2857H20.8093V28.4285Z" fill="#2D2E2F" />
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_2388_63054" x="8" y="10" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2388_63054" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2388_63054" result="shape" />
                    </filter>
                    <clipPath id="clip0_2388_63054">
                      <rect width="44" height="44" rx="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>


              </div>

              <div className="flex flex-col items-start">
                <div className="text-center text-base font-semibold ">
                  {t("Vank.Share.Sidebar.SendModal.Actions.Fiat.title")}
                </div>
                <div className="group-hover:block 2xl:text-sm xl:text-sm lg:text-sm tablet:text-sm mobile:text-xs text-left text-[#818282] dark:text-[var(--content-main-gray-dark)]">
                  {t("Vank.Share.Sidebar.SendModal.text1")}
                </div>
              </div>

            </div>

            <div>
              <ArrowHide className="-rotate-90 w-full h-full " />
            </div>

          </div>

        </div>
      </motion.div>
    </CustomModal>
  );
};

export default SendModal;