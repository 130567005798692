import { lazy } from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import RootLanding from "./apps/Root/RootLanding/RootLanding";
import { RootVank } from "./apps/Root/RootVank/RootVank";
import Recipient from "./apps/Vank/Page/Recipient/Recipient";
import Fiat from "./apps/Vank/Page/Transactions/Fiat/Fiat";
import { decodeToken } from "./apps/Shared/utils/Utils";
import { StorageService } from "./apps/Vank/Page/services/StorageServiceVanKPay";
import ErrorLayout from "./apps/Shared/layout/ErrorLayout";
import PublicWrapper from "./apps/Root/PublicWrapper/PublicWrapper";

// Función para verificar si hay un token y que el sub sea VANK_AUTHENTICATIONS
const isAuthenticated = async () => {
  try {
    const token = await StorageService.get("token");
    if (!token) {
      return false;
    }
    const decode = decodeToken(token);
    if (!decode && !decode?.sub) {
      return false;
    }
    return decode?.sub === "VANK_AUTHENTICATIONS"; // Verifica que el sub sea VANK_AUTHENTICATIONS
  } catch (error) {
    return false; // Si ocurre un error en la decodificación, retorna false
  }
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorLayout />,
    children: [
      {
        element: <PublicWrapper />,
        children: [
          //TODO: LANDING
          {
            element: <RootLanding />,
            loader: async () => {
              const isUser = await isAuthenticated();

              if (isUser) {
                return redirect("/Home"); // Si está autenticado, redirige a Home
              }
              return null; // Permite acceder si no está autenticado
            },
            children: [
              {
                path: "/",
                Component: lazy(() => import("./apps/Landing/Pages/Home/Home")),
              },
              {
                path: "/Companies",
                Component: lazy(
                  () => import("./apps/Landing/Pages/Company/Company")
                ),
              },
              {
                path: "/People",
                Component: lazy(
                  () => import("./apps/Landing/Pages/People/People")
                ),
              },
              {
                path: "/VankCards",
                Component: lazy(
                  () => import("./apps/Landing/Pages/VankCard/VankCard")
                ),
              },
              {
                path: "/VankVersity",
                Component: lazy(
                  () => import("./apps/Landing/Pages/VankVersity/VankVersity")
                ),
              },
              {
                path: "/Legal/Terms",
                Component: lazy(
                  () =>
                    import(
                      "./apps/Landing/Pages/LegalDocs/TermsAndConditions/TermsAndConditions"
                    )
                ),
              },
              {
                path: "/SingUp",
                Component: lazy(
                  () => import("./apps/Landing/Pages/SingUp/SingUp")
                ),
              },
              {
                path: "/Legal/DataTreatment",
                Component: lazy(
                  () =>
                    import(
                      "./apps/Landing/Pages/LegalDocs/DataTreatment/DataTreatment"
                    )
                ),
              },
            ],
          },
          //TODO: AUTH
          {
            loader: async () => {
              const isUser = await isAuthenticated();
              if (isUser) {
                return redirect("/Home"); // Si está autenticado, redirige a Home
              }

              return null; // Permite acceder si no está autenticado
            },
            children: [
              {
                path: "Auth/:mode",
                Component: lazy(() => import("./apps/Auth/Auth")),
              },
              {
                path: "Auth/AddPhone",
                Component: lazy(
                  () => import("./apps/Auth/SingUp/PhoneForm/PhoneForm")
                ),
              },
              {
                path: "Auth/OTPValidation",
                Component: lazy(
                  () =>
                    import(
                      "./apps/Auth/SingUp/OTPValidationPhone/OTPValidationPhone"
                    )
                ),
              },
              {
                path: "Auth/SetPassword",
                Component: lazy(
                  () => import("./apps/Auth/SingUp/SetPassword/SetPassword")
                ),
              },
            ],
          },
        ],
      },
      //TODO: HOME
      {
        element: <RootVank />,
        loader: async () => {
          const isUser = await isAuthenticated();
          if (isUser) {
            return null;
          }
          return redirect("/Auth/SingIn"); // Permite acceder si no está autenticado
        },
        children: [
          {
            path: "/Home",
            Component: lazy(() => import("@/apps/Vank/Page/Home/Home")),
          },
          {
            path: "/send/vankpay",
            Component: lazy(
              () => import("@/apps/Vank/Page/Transactions/VankPay/VankPay")
            ),
          },
          {
            path: "/send/crypto",
            Component: lazy(
              () =>
                import(
                  "@/apps/Vank/Page/Transactions/Crypto/SendCrypto/SendCrypto"
                )
            ),
          },
          {
            path: "/convert",
            Component: lazy(() => import("@/apps/Vank/Page/Convert/Convert")),
          },
          {
            // path: "/send/fiat",
            // Component: lazy(
            //   () => import("@/apps/Vank/Page/Transactions/Fiat/Fiat")
            // ),
            path: "/send/fiat/*",
            element: <Fiat />,
            children: [
              {
                path: "*",
                Component: lazy(
                  () =>
                    import(
                      "@/apps/Vank/Page/Transactions/Fiat/routers/FiatRouter"
                    )
                ),
              },
            ],
          },
          {
            path: "/receive/fiat",
            Component: lazy(
              () =>
                import(
                  "@/apps/Vank/Page/Transactions/Fiat/RechargeFiat/RechageFiat"
                )
            ),
          },
          {
            path: "/receive/Crypto",
            Component: lazy(
              () =>
                import(
                  "@/apps/Vank/Page/Transactions/Crypto/RechargeCrypto/RechargeCrypto"
                )
            ),
          },

          {
            path: "/recipient/*", // La ruta base para Recipient
            element: <Recipient />,
            children: [
              // Aquí importamos las rutas que están en RecipientRoutes
              {
                path: "*",
                Component: lazy(
                  () =>
                    import("@/apps/Vank/Page/Recipient/router/RecipientRoutes")
                ),
              },
            ],
          },
          {
            path: "/Accounts",
            children: [
              {
                path: "",
                Component: lazy(
                  () => import("./apps/Vank/Page/Account/MyAccounts/MyAccounts")
                ),
              },
              {
                path: ":accountId/:accountIden",
                Component: lazy(
                  () =>
                    import(
                      "./apps/Vank/Page/Account/AccountOverview/AccountOverview"
                    )
                ),
              },
            ],
          },
          {
            path: "/settings",
            Component: lazy(() => import("./apps/Vank/Page/settings/settings")),
          },

          {
            path: "/all/transactions",
            Component: lazy(
              () =>
                import("./apps/Vank/Page/Home/AllTransactions/AllTransactions")
            ),
          },
        ],
      },
      //TODO: LINK EXTERNO DE RECARGAR DINERO
      {
        path: "/ExternalRecharge/:encodedData",
        loader: async () => {
          const isUser = await isAuthenticated();
          if (isUser) {
            return null; // Si está autenticado, redirige a Home
          }
          return redirect("/Auth/SingIn"); // Permite acceder si no está autenticado
        },
        Component: lazy(
          () =>
            import(
              "./apps/Vank/Page/Transactions/Fiat/RechargeFiat/ExternalRecharge/ExternalRecharge"
            )
        ),
      },
      //TODO: PAGINAS NO ENCONTRADAS
      {
        path: "*",
        Component: lazy(() => import("./apps/Shared/NotFound/NotFound")),
      },
    ],
  },
]);

export default router;
