import { useQuery } from "@tanstack/react-query";

import { CacheDropDowns } from "../../infrastructura/Persistence/CacheDropDowns";
import { usePicklistState } from "../core/usePicklistState";

export const useCacheQuery = () => {
  const addPicklistState = usePicklistState();
  const cacheDropdowns = new CacheDropDowns();

  const { data, error, status, refetch } = useQuery({
    queryKey: ["cacheData"],
    queryFn: async () => {
      await cacheDropdowns.cache(addPicklistState);
      return true;
    },
    staleTime: 1000 * 60 * 5,
    retry: 3,
    // refetchInterval: 5000
  });

  return {
    data,
    error,
    status,
    refetch,
  };
};
