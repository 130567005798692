import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import "./CustomModal.css";

export const backStyle = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const CustomModal = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: any;
}) => {


  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isOpen) {
      // Si isOpen es true, oculta el scroll del body
      document.body.style.overflow = 'hidden';
      htmlElement.style.overflow = "hidden"; // Deshabilita el scroll
    } else {
      // Si isOpen es false, restablece el scroll
      document.body.style.overflow = '';
      htmlElement.style.overflow = "auto"; // Restaura el scroll
    }

    // Limpieza al desmontar el componente
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]); // Ejecutar el efecto cuando isOpen cambie

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          //   onClick={closeModalForm}
          className="bg-[#00000066] fixed 2xl:px-0 xl:px-0 lg:px-0 tablet:px-0 mobile:px-2 inset-0 z-50 flex justify-center items-center overflow-hidden"
          initial={backStyle.initial}
          animate={backStyle.animate}
          exit={backStyle.exit}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CustomModal;
