import { useState, useEffect, useRef } from "react";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { decodeToken } from "@/apps/Shared/utils/Utils";
import { checkTokenExpiration } from "@/apps/Shared/utils/checkTokenExpiration/checkTokenExpiration";

// Configuración del temporizador de inactividad (5 minutos)
const INACTIVITY_TIME = 5 * 60 * 1000; // 5 minutos // UTILIZAR DEV, PROD
const _INACTIVITY_TIME = import.meta.env.VITE_HALF_MINUTE; // 30 segundos // UTILIZAR LOCAL
const INACT_TIME_ONE_DAY = import.meta.env.VITE_ONE_DAY || 24 * 60 * 60 * 1000; // 1 día // UTILIZAR LOCAL

export function useInactivity() {
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [isOpenExpiry, setIsOpenExpiry] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null); // Mantener el timeout sin re-renderizar

  const resetTimer = async () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);

    const token = await StorageService.get("token");
    const decode = decodeToken(token);
    const isExpired = checkTokenExpiration(token);

    if (token && decode?.sub === "VANK_AUTHENTICATIONS" && !isExpired) {
      timeoutId.current = setTimeout(() => {
        setIsInactive(true);
        setIsOpenExpiry(true);
        setIsClose(true);
      }, INACT_TIME_ONE_DAY);
    }
  };

  const handleUserActivity = async () => {
    const inactivity = await StorageService.get("inactivity");
    setIsInactive(!!inactivity);
    setIsOpenExpiry(!!inactivity);

    const token = await StorageService.get("token");
    const decode = decodeToken(token);
    const isExpired = checkTokenExpiration(token);

    if (token && decode?.sub === "VANK_AUTHENTICATIONS" && !isExpired) {
      resetTimer();
    }
  };

  useEffect(() => {
    const events = [
      "mousemove",
      "keydown",
      "click",
      "scroll",
      "touchmove",
      "touchstart",
    ];
    events.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    handleUserActivity(); // Verificar inactividad al montar

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
    };
  }, []);

  return { isInactive, isOpenExpiry, setIsOpenExpiry, isClose };
}
