import { atom } from "recoil";

export const showBalanceState = atom({
  key: "showBalanceState",
  default: true, // Mostrar el saldo de forma predeterminada
});

export const currenciesState = atom({
  key: "currenciesState",
  default: {
    selectCurrency: {
      name: "",
      balance: "",
      amounts: {
        accounts: "",
        vankCard: "",
        wallet: "",
      },
    },
    showBalance: localStorage.getItem("showBalance")
      ? JSON.parse(localStorage.getItem("showBalance"))
      : "false", // Mostrar el saldo de forma predeterminada
  },
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.log("Nuevo estado de showBalance:", newValue.showBalance);
        localStorage.setItem("showBalance", JSON.stringify(newValue.showBalance));
      });
    },
  ],
});



export const UpdateProfileState = atom({
  key: "UpdateProfileState",
  default: {
    isImgPreview: false,
    error: "",
    imgSrc: {
      name: "",
      format: "",
      size: "",
      src: "",
      file: null
    },
    isLoadingImg: false,
    isModalPath: '',
  },
});
