import { RouterProvider } from "react-router-dom";
import { Context } from "@redtea/react-inversify";
import router from "./Router";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useEffect } from "react";
import CustomModal from "./apps/Shared/CustomModal/CustomModal";
import ModalInactAndExpi from "./apps/Root/RootVank/ModalInactAndExpi/ModalInactAndExpi";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import { useInactivity } from "./hooks/useInactivity"; // Importamos el hook de inactividad
import { useTranslation } from "react-i18next";
import "react-image-crop/dist/ReactCrop.css";

function App({ container }) {
  const { theme } = useTheme();
  const { isInactive, isOpenExpiry, setIsOpenExpiry, isClose } = useInactivity();
  const [t] = useTranslation("global");


  useEffect(() => {
    // fetccc()
    document.body.style.backgroundColor =
      theme === "dark"
        ? "var(--background-layaout-dark)"
        : "var(--color-lighter-white)";
  }, [theme]);


  return (
    <Context.Provider value={container}>
      <RouterProvider router={router} />
      <CustomModal isOpen={isOpenExpiry}>
        <ModalInactAndExpi
          type="Inactive"
          isClose={isClose}
          setIsOpenExpiry={setIsOpenExpiry}
        />
      </CustomModal>
    </Context.Provider>
  );
}

export default App;
