import { IndexedDBLocalDropdownRepository } from "../Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { StorageService } from "../Vank/Page/services/StorageServiceVanKPay";
import { socketConfigs } from "./environment.socket";
import { ServiceType, SocketConfig, WebSocketMap } from "./types";

class WebSocketManager {
  public connections: WebSocketMap = {}; // Almacena las conexiones WebSocket por ID
  public services = "";
  public listeners: Record<string, Record<string, (data: any) => void>> = {};

  private reconnectAttempts: Record<string, number> = {}; // Contador de intentos de reconexión
  private maxReconnectAttempts = 20; // Número máximo de intentos de reconexión
  private reconnectDelay = 2000; // Tiempo de espera inicial antes de intentar reconectar (en ms)

  // Guardamos los datos de conexión
  public saveConnectionData(id: string, data: { keyPublic: string; clientId: string }) {
    const storedData = JSON.parse(localStorage.getItem("webSocketConnections") || "{}");
    storedData[id] = data;
    localStorage.setItem("webSocketConnections", JSON.stringify(storedData));
  }

  // Método para crear y manejar WebSockets con reconexión
  async createWebSocket(id: string, baseUrl: string): Promise<void> {
    if (this.connections[id]) {
      console.warn(`[${id}] WebSocket already exists. Skipping creation.`);
      return;
    }

    try {
      const token = await StorageService.get("token");
      const verify = await StorageService.get("verify");
      const ipTimestamp = localStorage.getItem("ip");

      if (!token || !verify || !ipTimestamp) {
        console.error(`[${id}] Missing required parameters`);
        return Promise.reject(new Error("Missing required parameters"));
      }

      const visible = socketConfigs.find(c => c.id === id)?.ecs;

      // Construimos la URL con los parámetros
      const url = `${baseUrl}?Ip=${ipTimestamp}&Authorization=${token}&verify=${verify}`;
      // console.log(`[${id}] Creating WebSocket connection to:`, url);
      const socket = new WebSocket(url);

      return new Promise<void>((resolve, reject) => {
        socket.onopen = () => {
          console.log(`[${id}] WebSocket connected successfully.`);
          this.connections[id] = socket; // Guardar conexión
          this.reconnectAttempts[id] = 0; // Reiniciar intentos de reconexión

          // Evitar recargas múltiples en poco tiempo
          const lastReload = localStorage.getItem("lastWebSocketReload");
          const now = Date.now();

          if (!lastReload || now - parseInt(lastReload) > 5000) {
            console.log(`[${id}] Reloading page after successful WebSocket reconnection...`);
            localStorage.setItem("lastWebSocketReload", now.toString());
            
          } else {
            console.log(`[${id}] WebSocket reconnected recently. Skipping reload.`);
          }

          resolve();
        };

        // Si el WebSocket se cierra por el backend, intentar reconectar
        socket.onclose = (event) => {
          console.warn(`[${id}] WebSocket closed. Reason:`, event.reason);
          if (event.wasClean) {
            console.log(`[${id}] Connection closed cleanly. No need to reconnect.`);
          } else {
            console.log(`[${id}] Connection lost! Attempting to reconnect...`);
            this.handleReconnection(id, baseUrl);
          }
        };

        socket.onerror = (error) => {
          console.error(`[${id}] WebSocket error detected:`, error);
          reject(error);
        };
      });

    } catch (error) {
      console.error(`[${id}] Failed to create WebSocket:`, error);
      return Promise.reject(error);
    }
  }

  // Manejo de reconexión automática cuando el backend cierra la conexión
  private handleReconnection(id: string, baseUrl: string) {
    const attempts = this.reconnectAttempts[id] || 0;

    if (attempts >= this.maxReconnectAttempts) {
      console.error(`[${id}] Max reconnect attempts reached. Giving up.`);
      window.dispatchEvent(new Event("websocket-failed"));
      return;
    }

    const delay = this.reconnectDelay * Math.pow(2, attempts); // Backoff exponencial
    console.log(`[${id}] Attempting to reconnect in ${delay / 1000} seconds...`);

    setTimeout(() => {
      console.log(`[${id}] Reconnection attempt #${attempts + 1}`);

      // Cerrar y eliminar la conexión antigua antes de recrear una nueva
      if (this.connections[id]) {
        console.log(`[${id}] Cleaning up old connection before reconnecting.`);
        this.connections[id].close();
        delete this.connections[id];
      }

      this.reconnectAttempts[id] = attempts + 1;

      // 🔹 Disparar evento cuando se intenta reconectar
      window.dispatchEvent(
        new CustomEvent("websocket-reconnecting", { detail: this.reconnectAttempts[id] })
      );

      this.createWebSocket(id, baseUrl).catch((err) => {
        console.error(`[${id}] Reconnection failed:`, err);
      });
    }, delay);
  }

  // Obtener una conexión WebSocket específica
  getWebSocket(id: string): WebSocket | null {
    return this.connections[id] || null;
  }

  handleEvent(callback: (data: any) => void) {
    const connection = this.connections[this.services];
    if (connection) {
      connection.addEventListener("message", (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        callback(data);
      });
    }
  }

  
  // Cerrar una conexión WebSocket específica
  closeWebSocket(id: ServiceType): void {
    if (this.connections[id]) {
      this.connections[id].close();
      delete this.connections[id];
      delete this.reconnectAttempts[id]; // Resetear intentos de reconexión si se cierra manualmente
      console.log(`[${id}] WebSocket closed manually`);
    }
  }

  // Cerrar todas las conexiones WebSocket
  closeAllWebSockets(): void {
    Object.keys(this.connections).forEach((id: ServiceType) => this.closeWebSocket(id));
  }

  async getConnectionData(id: ServiceType) {
    const storedData = JSON.parse(localStorage.getItem("webSocketConnections") || "{}");
    const token = await StorageService.get("token");
    const verify = await StorageService.get("verify");
    const ipTimestamp = localStorage.getItem("ip");

    if (!storedData[id]) {
      return null;
    }

    return {
      clientId: storedData[id].clientId,
      keyPublic: storedData[id].keyPublic,
      token,
      verify,
      ipTimestamp,
    };
  }

  sendToSocket(data: any, services: ServiceType): any {
    const connection = this.connections[services];
    if (!connection || connection.readyState !== WebSocket.OPEN) {
      console.error(`Connection with ID "${services}" is not open.`);
      return;
    }
    connection.send(JSON.stringify(data));
  }
}

const webSocketManager = new WebSocketManager();
export default webSocketManager;

/*
Escala de mensajes de reconexión basada en el número máximo de intentos (`maxAttempts`).
Siempre se mostrarán los siguientes tres textos:
  - "attempt": 🔄 Reconectando... Intento {{count}}.
  - "issues": ⚠️ Estamos teniendo problemas para restablecer la conexión... Intento {{count}}.
  - "warning": 🚨 Intentando reconectar... Si el problema persiste, verifica tu conexión o recarga la página.

Caso 1: maxAttempts = 5
---------------------------------
1 - 2  → t("reconnect.attempt", { count: X })
3 - 4  → t("reconnect.issues", { count: X })
5      → t("reconnect.warning")

Caso 2: maxAttempts = 10
---------------------------------
1 - 4  → t("reconnect.attempt", { count: X })
5 - 7  → t("reconnect.issues", { count: X })
8 - 10 → t("reconnect.warning")

Caso 3: maxAttempts = 20
---------------------------------
1 - 7   → t("reconnect.attempt", { count: X })
8 - 13  → t("reconnect.issues", { count: X })
14 - 20 → t("reconnect.warning")
*/