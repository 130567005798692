import { useRecoilState } from "recoil";
import { UpdateProfileState } from "../../../state";
import { useTranslation } from "react-i18next";

import { default as toastHook } from "@/hooks/useToast";
import { servicesSetting } from "../../services/servicesSetting";
import { ProfileUser } from "@/apps/Shared/state/atoms";
import { usePicklistState } from "@/apps/Shared/hooks/core/usePicklistState";

const allowedFormats = ["jpeg", "png", "jpg", "gif", "webp"]; // Formatos permitidos

// const error = () => (<IconError />)

export const useOnSelectFileUpdateImg = (iconError: React.ReactNode) => {
  const [imgState, setImgState] = useRecoilState(UpdateProfileState);
  const { t } = useTranslation();

  const services = new servicesSetting();
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const addPicklistState = usePicklistState();

  const toast = toastHook();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    const fileFormat = file.type.split("/")[1];

    setImgState((prev) => ({ ...prev, isImgPreview: true }));

    if (!allowedFormats.includes(fileFormat)) {
      setImgState((prev) => ({
        ...prev,
        error: t("Vank.Setting.Profile.text1"),
        imgSrc: { name: "", format: "", size: "", src: "", file: null },
      }));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setImgState((prev) => ({
        ...prev,
        error: `${t("Vank.Setting.Profile.text2")} 5 ${t(
          "Vank.Setting.Profile.mb"
        )}.`,
        imgSrc: { name: "", format: "", size: "", src: "", file: null },
      }));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === "string") {
        setImgState((prev) => ({
          ...prev,
          imgSrc: {
            name: file.name,
            format: file.type,
            size: file.size.toString(),
            src: result,
            file: null,
          },
          error: "",
        }));
      }
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImg = async () => {
    setImgState((prev) => ({ ...prev, isLoadingImg: true }));

    try {
      const res = await services.deleteAvatarProfile();
      // setIsImgOption(false);

      const hasUser = {
        ...users,
        AVATAR: res?.Url,
      };

      addPicklistState("user", hasUser);
      setUsers((prevState: any) => ({
        ...prevState,
        ...hasUser,
      }));
    } catch (error) {
      if (error?.data?.statusCode === 404) {
        toast.error(t("Vank.Setting.Profile.text20"), {
          position: "top-right", // Obligatorio
          autoClose: 5000, // Opcional
          iconError: iconError,
          hideProgressBar: true, // Opcional
          isHovered: true,
        });
        return;
      }
      toast.error(t("Vank.Share.text15"), {
        position: "top-right", // Obligatorio
        autoClose: 5000, // Opcional
        iconError: iconError, // Opcional
        hideProgressBar: true, // Opcional
        isHovered: true,
      });
    } finally {
      // setIsDeleteImg(false);
      setImgState((prev) => ({ ...prev, isLoadingImg: false }));
    }
  };

  return { onSelectFile, handleDeleteImg };
};
