import { ServicesUser } from "@/apps/services/ServicesUser";
import { IndexedDBLocalDropdownRepository } from "./IndexedDBLocalDropdownRepository";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { jwtDecode } from "jwt-decode";
import { Services } from "./services/services";

interface DropDownConfig {
  id: string;
  localRepository: IndexedDBLocalDropdownRepository;
  remoteRepository: () => Promise<any>;
  updateEvent: string;
}

export class CacheDropDowns {
  config: DropDownConfig[];
  configNotAuth: DropDownConfig[];

  constructor() {
    const services = new Services();

    this.config = [
      {
        id: "localWallet",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryWallet(),
        updateEvent: "localWalletUpdated",
      },
      {
        id: "localCountryView",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryCountryView(),
        updateEvent: "localCountryViewUpdated",
      },
      {
        id: "localCurrency",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryCurrency(),
        updateEvent: "localCurrencyUpdated",
      },
      {
        id: "localRegion",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryRegion(),
        updateEvent: "localRegionUpdated",
      },
      // Añadir más configuraciones aquí si es necesario
    ];

    this.configNotAuth = [
      {
        id: "localRegion",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryRegion(),
        updateEvent: "localRegionUpdated",
      },
      // Añadir más configuraciones aquí si es necesario
    ];
  }

  async cache(setCache: (id: string, data: any) => void) {
    try {
      const results = await Promise.all(
        this.config.map(async (config) => {
          try {
            const data = await config?.remoteRepository();
            if (data?.length > 0) {
              setCache(config?.id, data);
            }
          } catch (error) {
            console.error(`Error caching ${config?.id}:`, error);
          }
        })
      );
      return results;
    } catch (error) {
      console.error("Something went wrong in caching:", error);
    }
  }

  async cacheNotAuth(setCache: (id: string, data: any) => void) {
    try {
      const results = await Promise.all(
        this.configNotAuth.map(async (config) => {
          try {
            const data = await config?.remoteRepository();
            if (data?.length > 0) {
              setCache(config?.id, data);
            }
          } catch (error) {
            console.error(`Error caching ${config?.id}:`, error);
          }
        })
      );
      return results;
    } catch (error) {
      console.error("Something went wrong in caching:", error);
    }
  }

  // --------------------- Auth -------------------------------
  async checkTokenExpiration(token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convertir milisegundos a segundos
      return decodedToken?.exp < currentTime;
    } catch (error) {
      // console.error("Error decoding token:", error);
      return true; // Si hay un error al decodificar, asumimos que el token es inválido o ha expirado
    }
  }

  async getUser() {
    try {
      const services = new ServicesUser();
      const token = await StorageService.get("token");
      if (!token) throw new Error("not-token");

      const Exp = await this.checkTokenExpiration(token);
      if (Exp) throw new Error("session-expired");

      const hasUser = await services.getUser();

      if (hasUser?.statusCode === 200 && hasUser?.body === null) {
        throw new Error("not-token");
      }
      return hasUser[0];
    } catch (error) {
      throw error; // Lanza el error correctamente
    }
  }
  // ----------------------------------------------------
}
