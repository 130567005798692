import { useQuery } from "@tanstack/react-query";

import { CacheDropDowns } from "../../infrastructura/Persistence/CacheDropDowns";
import { usePicklistState } from "../core/usePicklistState";

export const useCachePublicQuery = () => {
  const addPicklistState = usePicklistState();
  const cacheDropdowns = new CacheDropDowns();

  const { data, error, status, refetch } = useQuery({
    queryKey: ["cacheDataPublic"],
    queryFn: async () => {
      await cacheDropdowns.cacheNotAuth(addPicklistState);
      return true;
    },
    staleTime: 1000 * 60 * 5,
    retry: 1,
  });

  return {
    data,
    error,
    status,
    refetch,
  };
};
