import { atom, atomFamily } from "recoil";
import cacheDropdownEffect from "./effects/cacheDropdownEffect";

export const ProfileUser = atom({
  key: "ProfileUser", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  // effects: [InfoUserEffects("user")],
});

export const picklistCacheState = atomFamily<any, any>({
  key: "dynamicState",
  default: [],
  effects: (id: string) => [cacheDropdownEffect(id)],
});

export const messageModalState = atom({
  key: "messageModalState",
  default: {
    title: "",
    message: "",
    show: false,
    url: "", // Se mantiene en el atom pero no se pasa al estado local
    type: "", // Tipo de mensaje
  },
});