import { environment } from "@/environments/environment.dev";
import { AxiosClientRepository } from "../../Http/AxiosHttp";

export class Services extends AxiosClientRepository {
  
  
  async getLibraryCountryView() {
    try {
      let columns = "code"
      const url = `${environment.url_servers.url_library}/libraryName/COUNTRY_VIEW`;
      // const url = `https://gd3jyakmcm.us-east-2.awsapprunner.com/library/libraryName/COUNTRY_VIEW`;
      const response = await this.get({ url });
    
      return response?.body;
    } catch (error) {
     
      throw error;
    }
  }
  
  async getLibraryCurrency() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/COUNTRY`;
      const response = await this.get({ url });
     
      return response?.body;
    } catch (error) {
     
      throw error;
    }
  }

  async getLibraryTown() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/TOWN`;
      const response = await this.get({ url });
     
      return response?.body;
    } catch (error) {
      
      throw error;
    }
  }
  
  async getLibraryWallet() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/CRYPTO`;
      const response = await this.get({ url });
     
      return response?.body;
    } catch (error) {
     
      throw error;
    }
  }
  
  async getLibraryRegion() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/REGION_DATA_VIEW`;
      // const url = `https://gd3jyakmcm.us-east-2.awsapprunner.com/library/libraryName/REGION_DATA_VIEW`;
      const response = await this.get({ url });
     
      return response?.body;
    } catch (error) {
     
      throw error;
    }
  }
}
