import VankLogo from "@/assets/Icon/VankLogo";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import {
  IconHome,
  IconSettings,
  IconUsers,
  IconHelpCenter,
  IconArrowReceive,
  IconArrowSend,
  IconTransferConvert,
  IconCard,
  IconMenu,
} from "@/assets/IconV2/IconsSvg";
import { IconLogout } from "@/assets/Icon/IconLogout";
import { Tooltip } from "react-tooltip";
import "./Sidebar.css";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { counterSelector } from "@/apps/Vank/Page/Home/state";
import { useRecoilState } from "recoil";
import Onboarding from "@/apps/Vank/Page/Home/Onboarding/Onboarding";
import { IndexedDBLocalDropdownRepository } from "../../infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import HighlightWords from "../../HighlightWords/HighlightWords";
import { ServicesHome } from "@/apps/Vank/Page/Home/Services/ServicesHome";
import ButtonExpand from "../components/ButtonExpand/ButtonExpand";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import CustomModal from "../../CustomModal/CustomModal";
import ServiceSidebar from "@/apps/Shared/layout/Sildebar/ServiceSidebar";
import { clear } from "idb-keyval";
import { motion } from "framer-motion";
import { sliderState } from ".";
import { ProfileUser } from "../../state/atoms";

export const Sidebar = ({
  setIsOpenSend,
  setIsOpenReload,
  toggleOpen,
}: {
  setIsOpenSend?: any;
  setIsOpenReload?: any;
  toggleOpen?: any;
  externalExpanded?: boolean;
  setExternalExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const services = new ServicesHome();
  const serviceSidebar = new ServiceSidebar();
  const [t, i18n] = useTranslation("global");
  const [c, setC] = useState<string>("");
  const [activeHeader, setActiveHeader] = useState<number>(0);
  const [activeFooter, setActiveFooter] = useState<number>(0);
  // const [expanded, setExpanded] = useState(
  //   window.innerWidth > 760 && window.innerWidth < 1024 ? false : true
  // );
  const [expanded, setExpanded] = useRecoilState(sliderState);

  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const [loading, setLoading] = useState(false);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step9Ref = useRef<HTMLDivElement>(null);
  const step10Ref = useRef<HTMLDivElement>(null);

  const [isVisiNavMobil, setIsVisiNavMobil] = useState(false);

  //mobile
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedState = localStorage.getItem("isSidebarExpanded");
    return storedState === "true";
  });

  const listLinksHeader = [
    {
      id: 1,
      path: "/Home",
      icon: <IconHome />,
      text: t("Vank.Share.Sidebar.Link.Home"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    {
      id: 2,
      path: "/send",
      icon: <IconArrowSend />,
      text: t("Vank.Share.Sidebar.Link.Send"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    {
      id: 3,
      path: "/receive",
      icon: <IconArrowReceive />,
      text: t("Vank.Share.Sidebar.Link.Recharge"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    {
      id: 4,
      path: "/convert",
      icon: <IconTransferConvert />,
      text: t("Vank.Share.Sidebar.Link.Convert"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    {
      id: 5,
      path: "/recipient",
      icon: <IconUsers />,
      text: t("Vank.Share.Sidebar.Link.recipients"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    {
      id: 6,
      path: "/Accounts",
      icon: <IconCard />,
      text: t("Vank.Share.Sidebar.Link.Cards"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      activeHeader: true,
    },
    // {
    //   id: 7,
    //   path: "",
    //   icon: <IconMenu />,
    //   text: t("Menu"),
    //   className: `${"text-[18px] max-2xl:text-[15px]"}`,
    //   active: true,
    // },
  ];

  const listLinksFooter = [
    {
      id: 1,
      path: "/Help",
      icon: <IconHelpCenter />,
      className:
        "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]",
      text: t("Vank.Share.Sidebar.Link.Help"),
      link: `${location.pathname}`,
    },
    {
      id: 2,
      path: "/settings",
      icon: <IconSettings />,
      className:
        "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]",
      text: t("Vank.Share.Sidebar.Link.Settings"),
      link: `${location.pathname}`,
    },
    {
      id: 3,
      path: "/BtnLogOut",
      icon: <IconLogout />,
      className:
        "text-[var(--content-main-black-light)]  dark:text-[var(--content-main-black-light)]",

      text: t("Vank.Share.Sidebar.Link.BtnLogOut"),
      link: `${location.pathname}`,
    },
  ];

  useEffect(() => {
    const matchedHeaderLinks = listLinksHeader.find((link) =>
      location.pathname.includes(link?.path)
    );

    const matchedFooterLinks = listLinksFooter.find((link) =>
      location.pathname.includes(link?.path)
    );

    // const matchedIds = matchedLinks.map(link => link.id);
    setActiveHeader(matchedHeaderLinks?.id);
    setActiveFooter(matchedFooterLinks?.id);
  }, [location.pathname]);

  //recibe - muestra el valor que envia desde el home para expandir o no
  useEffect(() => {
    const handleStorageChange = () => {
      const isHomeExpanded = localStorage.getItem("isHomeExpanded") === "true";
      const isSidebarExpanded = localStorage.getItem("isSidebarExpanded") === "true";

      if (isHomeExpanded) {
        setIsExpanded(true);
      } else if (!isSidebarExpanded) {
        setIsExpanded(false);
      }
    };

    const handleResize = () => {};

    window.addEventListener("storage", handleStorageChange);
    handleStorageChange(); // Llamar inicialmente para actualizar el estado

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // En el otro documento
  useEffect(() => {
    const handler = () => {
      handleLogOut(); // Llama a la función de cierre de sesión
    };

    setIsVisiNavMobil(false);

    document.addEventListener("logoutEvent", handler);

    return () => {
      document.removeEventListener("logoutEvent", handler);
    };
  }, []);

  //efecto para ocultar la modal mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 773) {
        setIsExpanded(false);
      }
    };
    // Verifica el tamaño de la ventana en el primer renderizado
    handleResize();
    // Escucha cambios en el tamaño de la ventana
    window.addEventListener("resize", handleResize);
    // Limpia el event listener cuando el componente se desmonte
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleExpandSidebar = () => {
    setIsExpanded(!isExpanded);
    localStorage.setItem("isSidebarExpanded", (!isExpanded)?.toString());
    localStorage.setItem("isHomeExpanded", "false");
    window.dispatchEvent(new Event("storage")); // Dispara evento para sincronizar otros componentes
  };

  const OpenMenuMobile = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleLogOut = async () => {
    setLoading(true); // Activa el estado de carga
    serviceSidebar
      .LogOutUser()
      .then(() => {
        clear();
        setUsers("")
        StorageService.delete("token");
        StorageService.delete("verify");
        //localStorage.clear();
        localStorage.removeItem("ip");
        localStorage.removeItem("ipTimestamp");
        localStorage.removeItem("id");
        sessionStorage.clear();
        StorageService.clear();
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      })
      .finally(() => {
        setLoading(false); // Desactiva el estado de carga, tanto si la operación es exitosa como si falla
      });
  };

  // Efecto para desplazar la vista a esta sección cuando el counter coincida con stepNumber
  useEffect(() => {
    if (counter === 1 && step1Ref.current) {
      step1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else if (counter === 3 && step3Ref.current) {
      step3Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else if (counter === 8 && step9Ref.current) {
      step9Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else if (counter === 9 && step10Ref.current) {
      step10Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [counter]);

  //efecto para ocultar sidebar tablet
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 760 && window.innerWidth < 1024) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    };
    // Verifica el tamaño de la ventana en el primer renderizado
    handleResize();

    // Escucha cambios en el tamaño de la ventana
    window.addEventListener("resize", handleResize);
    // Limpia el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //para ocultar y visualizar la barra de inicio en la aparte del footer mobile
  useEffect(() => {
    const handleFocus = (e) => {
      if (e?.target?.tagName === "INPUT" || e?.target?.tagName === "TEXTAREA") {
        setIsVisiNavMobil(true);
      }
    };
    const handleBlur = (e) => {
      if (e?.target?.tagName === "INPUT" || e?.target?.tagName === "TEXTAREA") {
        setIsVisiNavMobil(false);
      }
    };
    const handleResize = () => {
      if (window.visualViewport.height < window.innerHeight) {
        setIsVisiNavMobil(true);
      } else {
        setIsVisiNavMobil(false);
      }
    };
    window.addEventListener("focusin", handleFocus);
    window.addEventListener("focusout", handleBlur);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("focusin", handleFocus);
      window.removeEventListener("focusout", handleBlur);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        className={`  h-full 2xl:block xl:block lg:block md:block mobile:hidden 2xl:min-h-screen xl:min-h-screen lg:min-h-screen md:min-h-screen sm:min-h-screen ${
          expanded
            ? "w-[234px] "
            : "lg:w-[108px] tablet:w-[48px] transition-all duration-700"
        } flex flex-col justify-between  lg:px-[24px] tablet:px-0 left-0 top-0 tablet:fixed lg:sticky z-50  bg-[var(--bacground-sidebar-light)] dark:bg-[--bacground-sidebar-dark]  items-start transition-all duration-700 `}
      >
        <div className="flex flex-col min-h-screen py-[36px] justify-between">
          <div className="w-full  h-full flex-col justify-start items-center gap-4 flex">
            <motion.div
              initial={false} // Para mantener el estado sin reiniciar
              animate={{ width: expanded ? "188px" : "52px" }} // Animación del ancho
              transition={{ duration: 0.5, ease: "easeInOut" }} // Tiempo y curva de animación
              className="h-[61px] flex-col justify-center items-center gap-2 inline-flex overflow-hidden"
            >
              {expanded ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <VankLogo
                    fill="var(--content-main-black-light)"
                    className="h-[40px] w-[117px]"
                  />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.7 }}
                >
                  {theme === "dark" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="52"
                      height="37"
                      viewBox="0 0 52 37"
                      fill="none"
                      className="lg:w-[52px] lg:h-[37px] tablet:w-[36px] tablet:h-[24px]"
                    >
                      <path
                        d="M32.0062 36.6943C32.0029 36.4253 31.99 36.1597 31.99 35.8897C31.99 29.3308 33.0743 22.9101 35.2429 16.6288C37.4126 10.3475 40.3074 5.13751 43.9293 1H35.1577C31.7256 4.63315 28.9625 9.62819 26.864 15.9851C24.7677 22.342 23.7179 28.9776 23.7179 35.8897C23.7179 36.1597 23.7297 36.4253 23.733 36.6943H32.0051H32.0062ZM15.7932 36.6943H24.3048L15.5116 2.26469H7L15.7932 36.6943Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="52"
                      height="37"
                      viewBox="0 0 52 37"
                      fill="none"
                      className="lg:w-[52px] lg:h-[37px] tablet:w-[36px] tablet:h-[24px]"
                    >
                      <path
                        d="M32.0062 36.6943C32.0029 36.4253 31.99 36.1597 31.99 35.8897C31.99 29.3308 33.0743 22.9101 35.2429 16.6288C37.4126 10.3475 40.3074 5.13751 43.9293 1H35.1577C31.7256 4.63315 28.9625 9.62819 26.864 15.9851C24.7677 22.342 23.7179 28.9776 23.7179 35.8897C23.7179 36.1597 23.7297 36.4253 23.733 36.6943H32.0051H32.0062ZM15.7932 36.6943H24.3048L15.5116 2.26469H7L15.7932 36.6943Z"
                        fill="var(--content-main-black-light)"
                      />
                    </svg>
                  )}
                </motion.div>
              )}
            </motion.div>

            {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
            <div
              className={`w-full h-[70%] flex-col justify-start items-start ${
                counter === 1 || counter === 3 ? "gap-0" : "gap-[1px]"
              } inline-flex `}
              style={{
                pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
              }}
            >
              {listLinksHeader.map((item, index) => (
                <React.Fragment key={item.id}>
                  <button
                    className={`relative w-full  tablet:flex tablet:justify-center tablet:items-center ${
                      activeHeader === item.id &&
                      (counter === 0 || counter > 12)
                        ? "shadow bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] lg:rounded-xl tablet:rounded-r-xl tablet:rounded-l-none"
                        : "shadow-none"
                    }
                        ${
                          counter === 4 && item.id === 4
                            ? "shadow  bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] hidden rounded-xl"
                            : "shadow-none"
                        } 
                    ${
                      counter === 1 &&
                      item.id === 1 &&
                      "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50 rounded-xl"
                    }
                    ${
                      counter === 3 &&
                      (item.id === 2 || item.id === 3 || item.id === 4) &&
                      "bg-[var(--bacground-component-module-light)] z-50 dark:bg-[--content-main-yellow-dark]"
                    }
                    ${counter === 3 && item.id === 2 && "rounded-t-xl"}
                    ${counter === 3 && item.id === 3 && "rounded-none"}
                    ${counter === 3 && item.id === 4 && "rounded-b-xl"}
                    ${
                      item.id === 7 &&
                      "shadow border-t border-t-[#FFF133]   hidden" &&
                      "shadow-none hidden"
                    }
                   
                    ${expanded ? "tablet:gap-4 p-4 lg:gap-4 pr-1" : "tablet:gap-0 py-4"}
                     justify-start items-center gap-4 inline-flex transition-all duration-700 cursor-pointer`}
                    onClick={() => {
                      if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                        setIsOpenSend(true);
                        return;
                      } else if (
                        item.text === t("Vank.Share.Sidebar.Link.Recharge")
                      ) {
                        setIsOpenReload(true);
                        return;
                      } else if (
                        item.text === t("Vank.Share.Sidebar.Link.Convert")
                      ) {
                        return;
                      } else if(item.text === t("Vank.Share.Sidebar.Link.Cards")) {
                        navigate(item.path);
                        return;
                      } else if (index === listLinksHeader?.length - 1) return;
                      navigate(item.path);
                    }}
                    disabled={users?.STATUSINCODE === 2}
                  >
                    <span className="w-7 h-7 flex justify-center items-center">
                      {item.icon}
                    </span>
                    <motion.div
                      className={`flex justify-start items-center gap-2.5 overflow-hidden `}
                      initial={false} // Para mantener el estado sin reiniciar
                      animate={{ width: expanded ? "150px" : "0px" }} // Animación del ancho
                      transition={{ duration: 0.4, ease: "easeInOut" }} // Tiempo y curva de animación
                    >
                      <div
                        className={`text-center text-start text-lg w-full ${
                          activeHeader === item.id
                            ? "font-medium "
                            : "font-normal"
                        } ${
                          !item?.activeHeader
                            ? "text-[var(--content-main-black-light)] font-medium"
                            : "text-[var(--content-main-black-light)]"
                           } dark:text-[--color-lightest-white] hover:tracking--1 hover:font-medium`}
                        id={
                          !item?.activeHeader
                            ? "my-anchor-element-id"
                            : undefined
                        }
                      >
                        {item.text}
                      </div>
                      <Tooltip
                        anchorSelect="#my-anchor-element-id"
                        content="Disponible muy pronto!"
                        place="right"
                        variant="light"
                        style={{
                          boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                          borderRadius: "8px",
                        }}
                      />
                    </motion.div>
                    {counter === 1 && item.id === 1 && (
                      <div
                        ref={step1Ref}
                        className="absolute min-w-[338px] min-h-[98px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-4 pl-10 pr-4 z-50"
                      >
                        <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                          <span className="text-[14px] text-[#000] font-normal">
                            {counter}
                          </span>
                        </div>
                        <p className="text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] leading-[21.78px] font-normal">
                          {HighlightWords(t("Vank.Home.Onboarding.One.text"), [
                            t(
                              "Vank.Home.Onboarding.One.boldText"
                            ).toLowerCase(),
                          ])}
                        </p>
                      </div>
                    )}

                    {index === 0 && counter === 3 && (
                      <div
                        ref={step3Ref}
                        className="absolute min-w-[446px] min-h-[76px] rounded-[16px] top-16 left-[110%] flex justify-center items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-4 pl-8 pr-4 z-50"
                      >
                        <div className="absolute -left-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                          <span className="text-[14px] text-[#000] font-normal">
                            {counter}
                          </span>
                        </div>
                        <p className="w-[398px] text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] leading-[21.78px] font-normal">
                          {t("Vank.Home.Onboarding.Three")}
                        </p>
                      </div>
                    )}
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="w-full  h-full  flex-col justify-end items-center gap-4 flex">
            {/* FOOTER: are the slider actions (Help, Settings, Exit). */}
            <div
              className="w-full  flex-col justify-center items-start inline-flex "
              style={{
                pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
              }}
            >
              {/* {listLinksFooter.map((item, index) => (
                <button
                  className={`relative w-full tablet:flex tablet:justify-center tablet:items-center
                    ${activeFooter === item.id
                      ? "shadow bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] lg:rounded-xl tablet:rounded-r-xl tablet:rounded-l-none"
                      : "shadow-none "
                    }
                      ${counter === 8 &&
                    item.id === 1 &&
                    "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50 rounded-xl"
                    }
                      ${counter === 9 &&
                    item.id === 2 &&
                    "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50 rounded-xl|"
                    }
                      ${expanded ? "tablet:gap-4 p-4" : "tablet:gap-0 py-4"}
                      transition-all duration-700`}
                  key={index}
                  onClick={() => {
                    if (item.text === t("Vank.Share.Sidebar.Link.BtnLogOut")) {
                      handleLogOut();
                    } else if (
                      item.text === t("Vank.Share.Sidebar.Link.Settings")
                    ) {
                      // updateOnboarding();
                      navigate(item.path);
                    }
                  }}
                  disabled={users?.STATUSINCODE === 2}
                >
                  <span className="w-7 h-7 flex justify-center items-center">
                    {item.icon}
                  </span>
                  <motion.div
                    className={`flex justify-start items-center gap-2.5  ${expanded
                      ? "opacity-100 transition-opacity duration-1000"
                      : "opacity-0 transition-opacity duration-200"
                      } `}
                    initial={false} // Para mantener el estado sin reiniciar
                    animate={{ width: expanded ? "110px" : "0px" }} // Animación del ancho
                    transition={{ duration: 0.4, ease: "easeInOut" }} // Tiempo y curva de animación
                  >
                    <div className="text-center text-[var(--content-main-black-light)] dark:text-[--color-lightest-white] text-lg font-normal">
                      {item.text}
                    </div>
                  </motion.div>
                  {counter === 8 && item.id === 1 && (
                    <div
                      ref={step9Ref}
                      className="absolute w-[500px] h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-4 pl-10 pr-4 z-50"
                    >
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] font-normal leading-[21.78px]">
                        {t("Vank.Home.Onboarding.Nine")}
                      </p>
                    </div>
                  )}
                  {counter === 9 && item.id === 2 && (
                    <div
                      ref={step10Ref}
                      className="absolute  w-[443px] min-h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-center items-start bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-4 pl-10 pr-4 z-50"
                    >
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] font-normal leading-[21.78px]">
                        {t("Vank.Home.Onboarding.Ten")}
                      </p>
                    </div>
                  )}
                </button>
              ))} */}
            </div>
          </div>
        </div>
        <button
          className="absolute z-20 top-[52px] -right-3 w-[28px] h-[28px] flex justify-center items-center bg-[var(--bacground-sidebar-light)] dark:bg-[--bacground-sidebar-dark] rounded-[14px]"
          onClick={() =>  setExpanded((curr) => !curr)}
        >
          <svg
            width="9.33"
            height=""
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H10.3333"
              stroke={
                theme === "dark"
                  ? "var(--color-lightest-white)"
                  : "var(--content-main-black-light)"
              }
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 5H6.33333"
              stroke={
                theme === "dark"
                  ? "var(--color-lightest-white)"
                  : "var(--content-main-black-light)"
              }
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 9H10.3333"
              stroke={
                theme === "dark"
                  ? "var(--color-lightest-white)"
                  : "var(--content-main-black-light)"
              }
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <Onboarding />
      </div>

      <div className="w-[48px] mobile:hidden tablet:block lg:hidden min-h-[300px]  2xl:min-h-screen xl:min-h-screen lg:min-h-screen md:min-h-screen sm:min-h-screen bg-[var(--bacground-sidebar-light)] dark:bg-red-700" />

      {/* mobile */}
      <div className={`${isExpanded ? "mobile" : ""}`}>
        <CustomModal isOpen={isExpanded}>
          <motion.div
            onClick={toggleExpandSidebar}
            className={`fixed h-full top-0 left-0 w-[236px] mobile:block 2xl:hidden mobile:min-h-screen bg-opacity-50  flex justify-between  bg-[var(--bacground-sidebar-light)] dark:bg-[--bacground-sidebar-dark] shadow z-30 items-start`}
            initial={{ translateX: "-100%" }}
            animate={{ translateX: isExpanded ? "0%" : "-100%" }}
            exit={{ translateX: "-100%" }}
            transition={{ duration: 0.3, ease: "linear" }}
            style={{ pointerEvents: isExpanded ? "auto" : "none" }}
          >
            <div className=" h-full flex items-start flex-col gap-16 px-2 py-[6rem]  overflow-y-auto">
              <div className="w-full   flex-col justify-start items-center gap-4 inline-flex">
                {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
                <div
                  className={` relative  left-0 w-full  flex-col justify-start items-start ${
                    counter === 1 || counter === 3 ? "gap-[0px]" : "gap-[1px]"
                  } inline-flex  `}
                  style={{
                    pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
                  }}
                >
                  {listLinksHeader.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <button
                        key={item.id}
                        className={`${
                          isExpanded
                            ? " w-full opacity-100"
                            : " w-0 opacity-0 pointer-events-none z-0"
                        } w-full relative  p-4 ${
                          activeHeader === item.id &&
                          (counter === 0 || counter > 12)
                            ? "shadow bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] rounded-xl"
                            : "shadow-none "
                        } 
                    ${
                      counter === 1 &&
                      item.id === 1 &&
                      "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50 rounded-xl"
                    }
                    ${
                      counter === 3 &&
                      (item.id === 2 || item.id === 3 || item.id === 4) &&
                      "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                    }
                    ${counter === 3 && item.id === 2 && "rounded-t-xl"}
                    ${counter === 3 && item.id === 3 && "rounded-none"}
                    ${counter === 3 && item.id === 4 && "rounded-b-xl"}

                    justify-start items-center gap-4 inline-flex hover:px-5 transition-all duration-300 cursor-pointer`}
                        onClick={() => {
                          if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                            setIsOpenSend(true);
                            return;
                          } else if (
                            item.text === t("Vank.Share.Sidebar.Link.Recharge")
                          ) {
                            setIsOpenReload(true);
                            return;
                          } else if (item.text === t("Vank.Share.Sidebar.Link.Convert")){
                            return
                          } else if(item.text === t("Vank.Share.Sidebar.Link.Cards")) {
                            navigate(item.path);
                            return;
                          } else if (index === listLinksHeader?.length - 1)
                            return;
                          navigate(item.path);
                        }}
                        disabled={users?.STATUSINCODE === 2}
                      >
                        <span className="w-7 h-7 flex justify-center items-center">
                          {item.icon}
                        </span>
                        <div
                          className={`${
                            isExpanded ? "w-[110px]" : "w-0 "
                          } justify-start items-center gap-2.5 flex transition-all duration-500 overflow-hidden`}
                        >
                          <div
                            className={`text-center text-lg mobile-375:text-[16px] mobile:text-[15px] ${
                              activeHeader === item.id
                                ? "font-medium"
                                : "font-normal"
                            } ${
                              !item?.activeHeader
                                ? "text-[#C0C0C1]"
                                : "var(--content-main-black-light)"
                            } dark:text-[#FFFFFF]`}
                            id={
                              !item?.activeHeader
                                ? "my-anchor-element-id"
                                : undefined
                            }
                          >
                            {item.text}
                          </div>
                          <Tooltip
                            anchorSelect="#my-anchor-element-id"
                            content="Disponible muy pronto!"
                            place="right"
                            variant="light"
                            style={{
                              boxShadow:
                                "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                              borderRadius: "8px",
                            }}
                          />
                        </div>
                        {counter === 1 && item.id === 1 && (
                          <div
                            ref={step1Ref}
                            className="absolute  min-w-[338px] min-h-[98px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50"
                          >
                            <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                              <span className="text-[14px] text-[#000] font-normal">
                                {counter}
                              </span>
                            </div>
                            <p className="text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                              {HighlightWords(
                                t("Vank.Home.Onboarding.One.text"),
                                [
                                  t(
                                    "Vank.Home.Onboarding.One.boldText"
                                  ).toLowerCase(),
                                ]
                              )}
                            </p>
                          </div>
                        )}
                        {index === 0 && counter === 3 && (
                          <div
                            ref={step3Ref}
                            className="absolute min-w-[446px] min-h-[76px] rounded-[16px] top-16 left-[110%] flex justify-center items-center bg-[var(--bacground-component-module-light)] py-4 pl-8 pr-4 z-50"
                          >
                            <div className="absolute -left-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                              <span className="text-[14px] text-[#000] font-normal">
                                {counter}
                              </span>
                            </div>
                            <p className="w-[398px] text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                              {t("Vank.Home.Onboarding.Three")}
                            </p>
                          </div>
                        )}
                      </button>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div className="w-full    flex-col justify-end items-center gap-4 inline-flex">
                {/* FOOTER: are the slider actions (Help, Settings, Exit). */}
                <div
                  className="w-full h-60 flex-col justify-center items-start inline-flex "
                  style={{
                    pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
                  }}
                >
                  {listLinksFooter.map((item, index) => (
                    <button
                      className={`${
                        isExpanded
                          ? " w-full opacity-100"
                          : " w-0 opacity-0 pointer-events-none z-0"
                      }  w-full min-h-[60px] p-4 rounded-xl justify-start items-center gap-4 inline-flex
                      ${
                        activeFooter === item.id
                          ? "shadow bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] rounded-xl"
                          : "shadow-none "
                      }
                      ${
                        counter === 8 &&
                        item.id === 2 &&
                        "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                      }
                      ${
                        counter === 9 &&
                        item.id === 3 &&
                        "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                      }
                      transition-all duration-300`}
                      key={item.id}
                      onClick={() => {
                        if (
                          item.text === t("Vank.Share.Sidebar.Link.BtnLogOut")
                        ) {
                          handleLogOut();
                        } else if (
                          item.text === t("Vank.Share.Sidebar.Link.Settings")
                        ) {
                          navigate(item.path);
                        }
                      }}
                      disabled={users?.STATUSINCODE === 2}
                    >
                      <span className="w-7 h-7">{item.icon}</span>
                      <div
                        className={`${
                          isExpanded ? "w-full" : "w-0"
                        }  justify-start items-center gap-2.5 transition-all duration-500 flex overflow-hidden `}
                      >
                        <div className="text-center text-[var(--content-main-black-light)] dark:text-[#FFFFFF] mobile-375:text-[16px] mobile:text-[15px]  font-normal">
                          {item.text}
                        </div>
                      </div>
                      {counter === 8 && item.id === 2 && (
                        <div
                          ref={step9Ref}
                          className="absolute w-[500px] h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50"
                        >
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="text-[18px] text-left text-[var(--content-main-black-light)] font-normal leading-[21.78px]">
                            {t("Vank.Home.Onboarding.Nine")}
                          </p>
                        </div>
                      )}
                      {counter === 9 && item.id === 3 && (
                        <div
                          ref={step10Ref}
                          className="absolute w-[443px] min-h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-center items-start bg-[var(--bacground-component-module-light)]  py-4 pl-10 pr-4 z-50"
                        >
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="text-[18px] text-left text-[#000] font-normal leading-[21.78px]">
                            {t("Vank.Home.Onboarding.Ten")}
                          </p>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <ButtonExpand
              setExpandebutton={toggleExpandSidebar}
              svgElementClose
              className={`${
                isExpanded ? "block" : "hidden"
              } absolute p-2 bg-[#fff] rounded-full top-[52px] -right-3 w-[28px] h-[28px] flex justify-center items-center bg-[var(--bacground-sidebar-light)] dark:bg-[--bacground-sidebar-dark]`}
            />
          </motion.div>
        </CustomModal>
      </div>
      {/**Nav mobile */}
      <div
        className={`fixed dark:bg-[var(--background-component-module-dark)] z-40 pt-2  bottom-0 w-full ${
          isVisiNavMobil
            ? "2xl:hidden xl:hidden lg:hidden tablet:hidden md:hidden  mobile:hidden"
            : "2xl:hidden xl:hidden lg:hidden tablet:hidden md:hidden  mobile:flex"
        }  justify-between  items-start`}
      >
        <div
          className={` bg-[var(--bacground-sidebar-light)] dark:bg-[var(--background-component-module-dark)] shadow z-50  w-full`}
        >
          <div className="w-full justify-start items-center ">
            {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
            <div
              className={`w-full  justify-start items-start inline-flex ${
                counter === 1 && "z-50"
              } `}
              style={{
                pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
              }}
            >
              {listLinksHeader.map((item, index) => (
                <React.Fragment key={item.id}>
                  <button
                    key={item.id}
                    className={`relative  w-full flex flex-col min-h-[59px] py-[8px] ${
                      (activeHeader === item.id && item.id === 1) ||
                      (activeHeader === item.id && item.id === 4)
                        ? "shadow border-t border-t-[#FFF133]    "
                        : "shadow-none "
                    } 
                          ${
                            activeHeader === item.id && item.id
                              ? "shadow border-t border-t-[#FFF133]  "
                              : "shadow-none "
                          } 
                          ${
                            item.id === 5 || item.id === 6
                              ? "shadow border-t border-t-[#FFF133]  hidden"
                              : "shadow-none "
                          }
                        ${
                          item.id === 7
                            ? "shadow border-t border-t-[#FFF133]  h-full"
                            : "shadow-none "
                        }
                          ${
                            counter === 1 &&
                            item.id === 1 &&
                            "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                          }
                          ${
                            counter === 3 &&
                            item.id === 2 &&
                            "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                          }
                          ${
                            counter === 3 &&
                            item.id === 3 &&
                            "bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] z-50"
                          }

                      justify-start items-center  inline-flex transition-all duration-300 cursor-pointer`}
                    onClick={() => {
                      if (item.id === 7) {
                        OpenMenuMobile();
                      }
                      if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                        setIsOpenSend(true);
                        return;
                      } else if (
                        item.text === t("Vank.Share.Sidebar.Link.Recharge")
                      ) {
                        setIsOpenReload(true);
                        return;
                      }  else if (
                        item.text === t("Vank.Share.Sidebar.Link.Convert")
                      ) {
                        return
                      }
                      else if(item.text === t("Vank.Share.Sidebar.Link.Cards")) {
                        navigate(item.path);
                        return;
                      }
                      else if (index === listLinksHeader?.length - 1) return;
                      navigate(item.path);
                    }}
                    disabled={users?.STATUSINCODE === 2}
                  >
                    <span className=" flex justify-center items-center">
                      {item.icon}
                    </span>
                    <div
                      className={`justify-start items-center px-2 flex transition-all duration-500 overflow-hidden`}
                    >
                      <div
                        className={`text-center font-semibold mobile-375:text-[12px] mobile:text-[11px] ${
                          activeHeader === item.id
                            ? "font-medium"
                            : "font-normal"
                        } ${
                          !item?.activeHeader
                            ? "text-[#C0C0C1]"
                            : "var(--content-main-black-light)"
                        } dark:text-[#FFFFFF]`}
                        id={
                          !item?.activeHeader
                            ? "my-anchor-element-id"
                            : undefined
                        }
                      >
                        {item.text}
                      </div>
                    </div>

                    {counter === 1 && item.id === 1 && (
                      <div
                        className="absolute mobile:min-w-[260px] mobile:left-8
                                        mobile-375:min-w-[321px] mobile-375:left-[2rem]
                        
                                    flex-wrap  rounded-[16px] -top-[5rem] -translate-y-1/2   flex justify-start items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-[12px] pl-[24px] pr-[12px] z-50"
                      >
                        <div className="absolute -left-2 -bottom-[1rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                          <span className="text-[14px] text-[#000] font-normal">
                            {counter}
                          </span>
                        </div>
                        <p className="text-[14px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] leading-[16.94px] font-medium">
                          {HighlightWords(t("Vank.Home.Onboarding.One.text"), [
                            t(
                              "Vank.Home.Onboarding.One.boldText"
                            ).toLowerCase(),
                          ])}
                        </p>
                      </div>
                    )}
                  </button>
                  {index === 0 && counter === 3 && (
                    <div className="absolute w-[305px] left-[calc(50%-150px)]  rounded-[16px] -top-[5rem] flex justify-start items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] pt-[12px] px-[12px] pb-[18px] z-50">
                      <div className="absolute left-0 right-0 mx-auto -bottom-[1.5rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[14px] text-center text-[var(--content-main-black-light)]  dark:text-[var(--content-main-black-dark)] leading-[16.94px] font-medium">
                        {t("Vank.Home.Onboarding.Three")}
                      </p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <Onboarding />
        </div>
      </div>
      {/**End Nav Mobile */}
      {loading && (
        <div className="fixed inset-0 z-50 flex justify-center items-center overflow-hidden bg-slate-50/70 dark:bg-black/70">
          <svg
            className="loader"
            viewBox="0 0 384 384"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
        </div>
      )}
    </div>
  );
};
