import { useRecoilCallback } from "recoil";

import { picklistCacheState } from "@shared/state/atoms";

export const usePicklistState = () => {
  const addPicklistState = useRecoilCallback(
    ({ set }) =>
      async (id: string, value: any) => {
        set(picklistCacheState(id), value);
      }
  );
  return addPicklistState;
};
