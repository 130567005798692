import { jwtDecode } from "jwt-decode";

export const checkTokenExpiration = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convertir milisegundos a segundos
      return decodedToken?.exp < currentTime;
    } catch (error) {
      // console.error("Error decoding token:", error);
      return true; // Si hay un error al decodificar, asumimos que el token es inválido o ha expirado
    }
  }