import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

interface ButtonNavigationProps {
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ButtonNavigation: React.FC<ButtonNavigationProps> = ({ counter, setCounter }) => {
    const [t, i18n] = useTranslation("global");

    // Creamos un nodo en el DOM al que haremos el portal
    const portalRoot = document.getElementById("portal-root-2") || document.body;

    // Creamos un contenedor para los botones dentro del portal
    const el = document.createElement("div");

    useEffect(() => {
        portalRoot.appendChild(el);

        // Cleanup para remover el contenedor al desmontar el componente
        return () => {
            portalRoot.removeChild(el);
        };
    }, [el, portalRoot]);

    // Aquí el contenido de los botones que se mostrará dentro del portal
    const buttonsContent = (
        <div
            className={`fixed max-w-[1440px] mx-auto 2xl:container bottom-0 left-0 right-0 w-full  flex items-center justify-between px-4 py-4 z-[9999] 
            ${counter === 0 ? "hidden" : ""} 
            `}  // Oculto solo en pantallas móviles (menos de 640px)
        >

            {/* Contenedor de los botones de navegación (Previous, Next) */}
            <div className="min-w-[222px] flex items-center gap-[8px] min-w-[222px] h-full flex items-center gap-[8px] 2xl:flex xl:flex lg:flex md:flex sm:hidden mobile:hidden ">
                <button
                    className="pointer-events-auto w-1/2 h-[28px] px-6 flex items-center justify-center bg-transparent border border-[#FFFFFF] text-[#FFFFFF] hover:border-2 disabled:hover:border disabled:text-opacity-[0.5] disabled:border-opacity-[0.5] rounded-[999px] cursor-pointer transition-all duration-200"
                    disabled={counter === 1}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        setCounter(counter - 1);
                    }}
                >
                    <span className="text-sm leading-[16.94px]">
                        {t("Vank.Home.Onboarding.Previous")}
                    </span>
                </button>

                <button
                    className="pointer-events-auto w-1/2 h-[28px] px-6 flex items-center justify-center bg-transparent border border-[#FFFFFF] text-[#FFFFFF] hover:border-2 disabled:hover:border disabled:text-opacity-[0.5] disabled:border-opacity-[0.5] rounded-[999px] cursor-pointer transition-all duration-200"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        setCounter(counter + 1);
                    }}
                >
                    <span className="text-sm text-[#FFFFFF] leading-[16.94px]">
                        {t("Vank.Home.Onboarding.Next")}
                    </span>
                </button>
            </div>

            {/* Botón "Skip" (Saltar) separado a la derecha */}
            <button
                className="pointer-events-auto 2xl:block xl:block lg:block md:block sm:hidden mobile:hidden relative bottom-0
              2xl:min-w-[177px] xl:min-w-[177px] lg:min-w-[177px] md:min-w-[177px] min-w-[177px] h-[28px] px-6 flex items-center justify-center bg-[#FFFFFF] dark:bg-transparent border border-transparent dark:border-[#FFFFFF]  rounded-[999px] cursor-pointer"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    setCounter(9);
                }}
            >
                <span className="text-sm text-[#2d2e2f] dark:text-white leading-[16.94px]">
                    {t("Vank.Home.Onboarding.Skip")}
                </span>
            </button>
        </div>
    );

    // Renderizamos los botones dentro del portal
    return createPortal(buttonsContent, el);
};

export default ButtonNavigation;
