import { AtomEffect, DefaultValue } from "recoil";
import { IndexedDBLocalDropdownRepository } from "../../infrastructura/Persistence/IndexedDBLocalDropdownRepository";

const indexDb = new IndexedDBLocalDropdownRepository();

const cacheDropdownEffect: (key: string) => AtomEffect<any> =
  (key) =>
  ({ setSelf, onSet }) => {
    indexDb
      .get(key)
      .then((savedValue) => setSelf(savedValue ?? new DefaultValue()))
      .catch(() => setSelf(new DefaultValue()));

    onSet(async (newValue, _, isReset) => {
      return isReset
        ? await indexDb.remove(key)
        : await indexDb.save({ key, data: newValue });
    });
  };

export default cacheDropdownEffect;
