const protocoloService={
  ws: "wss",
  http: ""
}

export const environment = {
  name: "service  desarrollo it1 pruebas",
  host: "localhost",
  port: "8000",
  protocolo: "http",

  headers: {
    Fiat: "ecs-fiat",
    Incode: "ecs-incode",
    Library: "ecs-library",
    Operations: "ecs-operations",
    Orchestrator: "ecs-orchestrator",
    Prints: "ecs-prints",
    Users: "ecs-users",
    Vankorchestrator: "ecs-vankorchestrator"
  },
  
  url_servers: {
    url_orchertractor:
      "https://orchestratorstb.thisisvank.com/orchestrator",
    // "https://v5mhyowde8.execute-api.sa-east-1.amazonaws.com/binanceorchestrator",

    url_users: "https://dkjtral0z2.execute-api.sa-east-1.amazonaws.com/users",

    url_library:
      "https://5hl2u11ayf.execute-api.sa-east-1.amazonaws.com/library",

    url_operation: "https://operationstb.thisisvank.com/operations/secure/v1",

    url_usersAWS:
      "https://dkjtral0z2.execute-api.sa-east-1.amazonaws.com/users",

    url_prints: "https://printsstb.thisisvank.com/prints",

    url_fiat: "https://yskkfj5yfl.execute-api.sa-east-1.amazonaws.com",

    url_upload_excel: "https://nvlzhnmj-3033.use2.devtunnels.ms",

    url_batch_template: "https://templatesvank.s3.us-west-2.amazonaws.com",

    url_crypto:" https://cryptoorchestrator.thisisvank.com/bitget"
  },

  url_externas: {
    url_realTimeValue:
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd",

    url_binance: "https://tlkh3ywbu5.execute-api.sa-east-1.amazonaws.com",

    //url_incode: "https://mxt1qql79a.execute-api.sa-east-1.amazonaws.com/incode",
    url_incode:
      " https://4vbjczjypd.execute-api.sa-east-1.amazonaws.com/incode",
  },
 
  url_socket: {
    url_vank_orchestractor: `${protocoloService.ws}://vankorchestratorstb.thisisvank.com`,
    url_operations:`${protocoloService.ws}://operationstb.thisisvank.com`
  }
};
