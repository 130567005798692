// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

const FullScreenLoader = ({ text }: { text?: string }) => {
  //Estado para la función de traducción
  // const [t] = useTranslation("global");
  // const [theme, setTheme] = useState(() => {
  //   const storedMode = localStorage.getItem("mode");
  //   return storedMode ? storedMode : "dark";
  // });

  return (
    <div className="w-full h-screen bg-[--color-lighter-white] dark:bg-[#1A1A1B] flex-col justify-center items-center gap-4 inline-flex overflow-hidden">
      <svg
        className="loader"
        viewBox="0 0 384 384"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="active"
          pathLength="360"
          fill="transparent"
          strokeWidth="32"
          cx="192"
          cy="192"
          r="176"
        ></circle>
        <circle
          className="track"
          pathLength="360"
          fill="transparent"
          strokeWidth="32"
          cx="192"
          cy="192"
          r="176"
        ></circle>
      </svg>
      {text && (
        <p className="text-[14px] text-[#2D2E2F] font-[500] leading-[100%]">
          {text}
        </p>
      )}
    </div>
  );
};

export default FullScreenLoader;
