import { Outlet } from "react-router-dom";
import "../RootVank/RootVank.css";
import FullScreenLoader from "@/apps/Shared/FullScreenLoader/FullScreenLoader";
import { useCachePublicQuery } from "@/apps/Shared/hooks/queries/useCachePublicQuery";

const PublicWrapper = () => {
  const { status: cacheDataStatus } = useCachePublicQuery();
  const loading = cacheDataStatus === "pending";

  if (loading) {
    return <FullScreenLoader />;
  }

  return <Outlet />;
};

export default PublicWrapper;
