import React from 'react'
import ErrorComputer from "@assets/Icon/ErrorComputer.png";


const ServerError = () => {
    return (
        <div className="grid h-screen place-items-center bg-white dark:bg-[#14181F] px-6 py-24 sm:py-32 lg:px-8">
          <div className="relative max-w-[1000px] w-full flex flex-col justify-start items-start gap-[16px]">
            <h1 className="text-[48px] font-bold text-[#2D2E2F] dark:text-[#FFFFFF] leading-[52px]">
              Internal Server Error!
            </h1>
            <p className="w-[400px] text-[18px] text-left leading-[21.78px] text-[#818282] dark:text-[#C0C0C1]">
              Lo sentimos, hubo algunos problemas técnicos al procesar su
              solicitud.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="rounded-[999px] px-[36px] py-[10px] text-sm font-semibold text-[#2D2E2F] dark:text-[#2D2E2F] shadow-sm bg-[#FFF133] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-colors duration-200 ease-out"
            >
              Reintentar
            </button>
            <div className="absolute top-1/2 -translate-y-1/2 w-[500px] h-[500px] 2xl:-right-0 xl:-right-0 lg:-right-0 tablet:-right-32 mobile:-right-32 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
              <img
                src={ErrorComputer}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      );
}

export default ServerError