import React from "react";
import next from '@assets/img/Next.png';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

interface ArrowNavigationProps {
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ArrowNavigation: React.FC<ArrowNavigationProps> = ({ counter, setCounter }) => {
  const [t, i18n] = useTranslation("global");
    
    const portalRoot: HTMLElement | null = document.getElementById('portal-root-1');

    if (!portalRoot) {
        
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <div className="fixed z-[100] h-screen left-0 h-[100px] px-2 w-full flex justify-between items-center gap-[8px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex mobile:flex">
                <button
                    className={` ${counter === 1 ? 'opacity-30' : 'opacity-100'} pointer-events-auto transform rotate-180 rounded-[999px] cursor-pointer transition-all duration-200 `}
                    disabled={counter === 1}
                    onClick={(event: any) => {
                        if (counter !== 1) {
                            event.stopPropagation();
                            setCounter(counter - 1);
                        }
                    }}
                >
                    <img src={next} alt="Previous" className="w-[2.5rem]" />
                </button>

                <div
                    className="pointer-events-auto rounded-[999px] cursor-pointer transition-all duration-200"
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                        setCounter(counter + 1);
                    }}
                >
                    <img src={next} alt="next" className="w-[2.5rem]" />
                </div>
                   {/**Skip**/}
            <button
                className={`pointer-events-auto absolute 2xl:-top-[0rem] xl:-top-[0rem] lg:-top-[0rem] md:-top-[0rem] mobile:absolute mobile:top-[85px] mobile:right-[16px] 
                2xl:min-w-[177px] xl:min-w-[177px] lg:min-w-[177px] md:min-w-[177px]
                h-[28px] px-6 flex items-center justify-center bg-[#ffffff] dark:bg-transparent border border-transparent dark:border-[#FFFFFF] rounded-[999px] cursor-pointer  ${counter === 9 || counter === 12 ? '2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:hidden' : '2xl:hidden xl:hidden lg:hidden md:hidden sm:block mobile:block'}`}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    setCounter(9);
                }}
            >
                <span className="text-sm text-[#2d2e2f] dark:text-white leading-[16.94px]">
                    {t("Vank.Home.Onboarding.Skip").split(' ').filter((_, index) => index !== 1).join(' ')}
                </span>
            </button>
            </div>
      
        </>,
        portalRoot
    );
};

export default ArrowNavigation;
