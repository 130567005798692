import { atom, selector } from "recoil";

interface GlobalAmountState {
  currency: string;
  amount: any; // Puedes definir 'any' o un tipo más específico según tus necesidades
}

export const filter = atom({
  key: "filter", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const operationsHome = atom({
  key: "operationsHome", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

// select cryptos balances
export const selectCryptos = atom({
  key: "selectCryptos", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

// select cryptos balances
export const globalAmount = atom<GlobalAmountState>({
  key: "globalAmount", // unique ID (with respect to other atoms/selectors)
  default: {
    currency: "COP", // Moneda predeterminada
    amount: {}, // Monto inicial
  },
});

// select cryptos balances
// export const ProfileUser = atom({
//   key: "ProfileUser", // unique ID (with respect to other atoms/selectors)
//   default: "", // default value (aka initial value)
// });

// select cryptos balances
// export const SelectTransaction = atom({
//   key: "SelectTransaction", // unique ID (with respect to other atoms/selectors)
//   default: "", // default value (aka initial value)
// });

// -----------ONBOARDING ---------------------

// Atom para el estado del contador
export const counterState = atom({
  key: 'counterState', // clave única para identificar el atom
  default: 13, // valor inicial
});

// Selector para incrementar o decrementar el contador
export const counterSelector = selector({
  key: 'counterSelector', // clave única para identificar el selector
  get: ({ get }) => get(counterState),
  set: ({ set }, newValue) => set(counterState, newValue),
});

export const dataAccounts = atom({
  key: 'dataAccounts',
  default: []
})

export const dataAMOUNTGLOBAL = atom({
  key: 'dataAMOUNTGLOBAL',
  default: {
    AMOUNT_GLOBAL: "",
    AMOUNT_GLOBAL_ACCOUNTS: "",
    LEVEL: "",
    PERCENTAGE: "",
    SYMBOL: "",
    TOTAL_TRANSACTIONS: "",
    event: "",
    statusCode: {}
  }
})

export const postEventGlobal = atom({
  key: 'postEventGlobal',
  default:{
    event:""
  }
})

