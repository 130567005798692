import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
export const AlertCopied = ({ moreStyle }: { moreStyle?:string }) => {
    const [t] = useTranslation("global");
    return (
        <motion.div

            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.5 }}
            className={`absolute ${moreStyle} 2xl:left-4 xl:left-4 lgt:-left-0 tablet:-left-0 mobile:-left-4 bg-[#80808088] font-bol  px-2 text-xs rounded-lg text-[#2D2E2F]`}
        >
            {t("Transactions.shared.AlertCopied.AlertCopied.Copied")}
        </motion.div>
    )
}
