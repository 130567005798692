import { useQuery } from "@tanstack/react-query";
import { CacheDropDowns } from "../../infrastructura/Persistence/CacheDropDowns";
import { ProfileUser } from "../../state/atoms";
import { useRecoilState } from "recoil";
import { usePicklistState } from "../core/usePicklistState";

export const userInfoQuery = () => {
  const cacheDropdowns = new CacheDropDowns();
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const addPicklistState = usePicklistState();

  const fetchUser = async () => {
    try {
      const user = await cacheDropdowns.getUser();
      if (!user) return null;
      const _user = {
        ...user,
        AVATAR: user?.AVATAR,
        EMAIL: user?.EMAILUSERS,
        VANKID: user?.VANKID,
      };
      addPicklistState("user", _user);
      setUsers((prevState: any) => ({
        ...prevState,
        ..._user,
      }));
      return user;
    } catch (error) {
      throw error;
    }
  };

  const { data, error, isError, status, refetch, failureCount } = useQuery({
    queryKey: ["user"],
    queryFn: fetchUser,
    staleTime: 0,
    refetchOnMount: true,
    retry: (failureCount, error) => {
      if (
        error.message === "session-expired" ||
        error.message === "not-token"
      ) {
        return false;
      }
      return failureCount < 5; // Reintentar para cualquier otro error
    },
    retryDelay: () => 3000, // Siempre espera 3s antes de reintentar

  });

  return {
    data,
    error,
    isError,
    status,
    refetch,
    failureCount,
  };
};
